import { UIMatch, useMatches } from '@remix-run/react';
import { type ClassValue, clsx } from 'clsx';
import { parseDomain as _parseDomain, ParseResultType } from 'parse-domain';
import { getClientIPAddress } from 'remix-utils/get-client-ip-address';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import type { loader as RootAppLayoutLoader } from '~/routes/app+/_layout';
import type { RequestGeolocation } from '~/shared/types/request-geolocation.type';
import type { SessionUserData } from '~/shared/types/session.type';

const intlDisplayNames = new Intl.DisplayNames(['en'], { type: 'region' });

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useAppContext() {
  const matches = useMatches();
  const appData = matches.find((m) => m.pathname === '/app') as UIMatch<
    typeof RootAppLayoutLoader
  >;

  return appData?.data.context;
}

export function useSessionUserData(): SessionUserData | null {
  const matches = useMatches();
  const appData = matches.find((m) => m.pathname === '/app') as UIMatch<
    typeof RootAppLayoutLoader
  >;

  return appData?.data.user ?? null;
}

export function throwOnUnexpectedContentType(
  request: Request,
  expectedContentType: string,
) {
  const receivedContentType = request.headers.get('content-type');
  if (receivedContentType !== expectedContentType) {
    throw new Error(
      `Unexpected content-type header received: ${receivedContentType}`,
    );
  }
}

export async function getExpectedJson(request: Request) {
  throwOnUnexpectedContentType(request, 'application/json');

  return request.json();
}

export function getRequestUrl(request: Request) {
  const url = new URL(request.url);

  return { url, searchParams: url.searchParams };
}

export const getTruncatedEpochTime = () => Math.round(Date.now() / 1000);

export function getValues<T extends Record<string, any>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]];
}

export async function getRequestGeolocation(request: Request) {
  const ipAddress = getClientIPAddress(request) ?? '(unknown)';
  const ipLocation = 'Somewhere Over, The Rainbow'; // TODO: Update this once IP enrichment is available

  const result: RequestGeolocation = {
    ipAddress: ipAddress,
    namedLocation: ipLocation,
  };

  return result;
}

export function zodEnumFromObjValues<K extends string>(
  obj: Record<K, any>,
): z.ZodEnum<[K, ...K[]]> {
  const [firstKey, ...otherKeys] = Object.values(obj) as K[];
  return z.enum([firstKey, ...otherKeys]);
}

export function toWordCase(value: string | undefined, separator = ' ') {
  if (value === undefined) return value;

  const values = value.split(separator);

  let result = '';

  for (const val of values) {
    result += `${val.charAt(0)}${val.slice(1).toLowerCase()}`;
  }

  return result;
}

export function getFlagEmoji(countryCode?: string) {
  if (countryCode === undefined || countryCode === 'N/A') return '';
  
  return countryCode
    ? String.fromCodePoint(
        ...[...countryCode.toUpperCase()].map(
          (x) => 0x1f1a5 + (x as any).charCodeAt(),
        ),
      )
    : '';
}

export function getCountryNameFromCountryCode(countryCode?: string) {
  if (countryCode === undefined || countryCode === 'N/A') return '';

  return intlDisplayNames.of(countryCode);
}

export function getDateFromMicrosecondEpochTime(epochTime: number) {
  return new Date(epochTime / 1000);
}

export const parseDomain = (domain: string) => {
  if (domain.slice(-1) === '.') {
    domain = domain.slice(0, -1); // remove trailing dots
  }
  const parsedDomain = _parseDomain(domain);

  if (parsedDomain.type !== ParseResultType.Listed) return undefined;
  if (parsedDomain.domain === undefined) return undefined;

  return {
    subdomain: parsedDomain.subDomains.join('.') || undefined,
    baseDomain: `${parsedDomain.domain}.${parsedDomain.topLevelDomains.join('.')}`,
    fullDomain: parsedDomain.hostname,
  };
};

export function filterUniqueFn(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}
